import {createClient} from '@supabase/supabase-js'
import AsyncStorage from "@react-native-async-storage/async-storage";


const SUPABASE_URL = "https://rtutcihxxdphpqszfast.supabase.co"
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ0dXRjaWh4eGRwaHBxc3pmYXN0Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY0NzIyODUwNywiZXhwIjoxOTYyODA0NTA3fQ.0yfcTqCM6spRrqDd-c2sukXw09mPrCNFy6k6t7q6CVo'
const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY, {
    localStorage: AsyncStorage
});

console.log()
const getAllPostings = async () => {
    let {data: posts, error} = await supabase
        .from('posts')
        .select('*')
        .order('id', {ascending: false})
    return {posts, error}
}
console.log(getAllPostings())

export {supabase, getAllPostings}
