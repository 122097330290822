import styled from "styled-components";

const Card = styled.div`
  background-color: ${({ isEmpty }) => (isEmpty ? "#FAFAFA" : "white")};
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin: 15px 0px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: rgb(80, 35, 20);
`;

const Footer = styled.div`
  background-color: rgb(242, 247, 251);
  height: 35px; /* changed */
  font-size: 16px;
  line-height: 12px;
  color: #4f4f4f;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  cursor: default;
  transition: all 0.3s ease-in-out; /* added */
  

  span {
    padding: 12px 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  &.show {
    height: auto;
  }
`;

export { Card, Header, Footer };
