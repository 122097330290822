import "./App.css";
import {Card, Footer, Header} from "./styles";
import {getAllPostings} from "./supabase-client";
import React, {useEffect, useRef, useState} from "react";
import parse from "html-react-parser";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";

function App() {
  return (
    <div>
        <h2>Site Under Construction</h2>
    </div>
  );
}

export default App;
