import './App.css';
import Home from "./Home";
import Admin from "./Admin";
import {Routes, Route} from "react-router-dom";

function App() {
    return (
        <div>
          <Routes><Route exact path="/" element={<Home />}></Route></Routes>
          <Routes><Route exact path="/admin" element={<Admin />}></Route></Routes>
        </div>

    );
}
export default App;



